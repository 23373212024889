<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo mt-0 mb-2">
          <img
            :src="style.loginImage"
            width="150px"
          >
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('Alterar senha') }}
        </b-card-title>
        <!-- <b-card-text class="mb-2">
          {{ $t('Sua nova senha deve ser diferente da senha anterior') }}
        </b-card-text> -->

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="submitForm"
          >
            <!-- old password -->
            <b-form-group
              :label="$t('Senha atual')"
              label-for="reset-password-old"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Senha atual')"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-old"
                    v-model="formData.oldPassword"
                    :type="passwordOldFieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-old"
                    placeholder="············"
                    autocomplete="current-password"
                    data-private
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordOldToggleIcon"
                      @click="togglePasswordOldVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              :label="$t('Nova senha')"
              label-for="reset-password-new"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Nova senha')"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="formData.password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                    autocomplete="new-password"
                    data-private
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              :label="$t('Confirmar nova senha')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Confirmar nova senha')"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="formData.cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                    autocomplete="off"
                    data-private
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <e-button
              :text="$t('Alterar senha')"
              type="submit"
              block
              variant="primary"
            />
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('Voltar') }}
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BCardTitle,
  // BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@/@core/mixins/ui/forms'
import { acl, app } from '@/mixins'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import EButton from '@/views/components/EButton.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    // BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    EButton,
  },

  mixins: [togglePasswordVisibility, acl, app],

  data() {
    return {
      formData: {
        userEmail: '',
        oldPassword: '',
        password: '',
        cPassword: '',
      },
      // validation
      required,

      // Toggle Password
      passwordOldFieldType: 'password',
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },

  computed: {
    passwordOldToggleIcon() {
      return this.passwordOldFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    togglePasswordOldVisibility() {
      this.passwordOldFieldType = this.passwordOldFieldType === 'password' ? 'text' : 'password'
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },

    async submitForm() {
      try {
        const validate = await this.$refs.simpleRules.validate()
        if (validate) {

          const body = {
            oldPassword: this.formData.oldPassword,
            newPassword: this.formData.password,
          }
          await this.$http.put('/api/auth/change-password', body)

          this.showSuccess({ title: this.$t('Alterado com sucesso'), icon: 'EditIcon' })
          // this.$router.push({ name: 'auth-login' })
          this.doLogout()

        }
      } catch (error) {
        this.showError({ error })
      }
    },

    async doLogout() {
      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      // Setando o estilo do operador para vazio, a aplicação não é mais exibida, e o spinning vai ficar no lugar.
      await this.$store.dispatch('app/setEstiloOperador', {})
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block'
      }
      useJwt.logout()

      // Reset ability
      this.$ability.update(initialAbility)

      if (this.isElectron) {
          window.location.href = window.electronAPP.loadURL
      } else {
        // Usando $router.go para resetar todo o estado da aplicação (Vuex)
        this.$router.go()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
